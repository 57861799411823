import { Link } from "gatsby";
import React from "react";
import LongLogo from "../svg/LongLogo";
import useMenu from "../zustand/useMenu";
import Facebook from "../svg/Facebook";
import Vimeo from "../svg/Vimeo";
import Instagram from "../svg/Instagram";
import Youtube from "../svg/Youtube";
import { SiTiktok } from "react-icons/si";

/* Footer
============================================================================= */

const Footer = ({ siteTitle }) => {
	// Config
	const { open, setOpen } = useMenu();

	// Render
	return (
		<footer className="w-full bg-orange py-8" style={{ zIndex: 100 }}>
			<div className="flex flex-col items-center container container-padding justify-between mx-auto">
				<div className="lg:w-full">
					<LongLogo />
				</div>
				<div className="lg:w-1/5 w-4/5 lg:py-16 py-8 flex flex-row items-center justify-center">
					<a
						href="https://www.facebook.com/isolaidfestival/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Facebook />
					</a>
					<div className="w-6" />
					<a
						href="https://www.instagram.com/isolaidfestival"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Instagram />
					</a>
					<div className="w-6" />
					<a
						href="https://www.tiktok.com/@isolaidfestival"
						target="_blank"
						rel="noopener noreferrer"
					>
						<SiTiktok color="#FEE4D2" size={30} />
					</a>
				</div>
				<div className="w-full flex lg:flex-row flex-col items-center justify-center">
					<Link
						to="/watch"
						className="px-5 ibm-condensed text-beige font-bold text-base py-2 lg:py-0"
					>
						WATCH
					</Link>
					<Link
						to="/contact"
						className="px-5 ibm-condensed text-beige font-bold text-base py-2 lg:py-0"
					>
						CONTACT US
					</Link>
					<Link
						to="/faqs"
						className="px-5 ibm-condensed text-beige font-bold text-base py-2 lg:py-0"
					>
						FAQS
					</Link>
					<a
						className="px-5 ibm-condensed text-beige font-bold text-base py-2 lg:py-0"
						href="https://store.sound-merch.com.au/collections/isol-aid"
					>
						MERCH
					</a>
					<Link
						to="/about"
						className="px-5 ibm-condensed text-beige font-bold text-base py-2 lg:py-0"
					>
						ABOUT
					</Link>
					<Link
						to="/howitworks"
						className="px-5 ibm-condensed text-beige font-bold text-base py-2 lg:py-0"
					>
						HOW IT WORKS
					</Link>
					{/* <a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.ausmusictshirtday.org.au/teams/isol-aid-festival-2"
						className="px-5 ibm-condensed text-beige font-bold text-base py-2 lg:py-0"
					>
						DONATE TO SUPPORT ACT
					</a> */}
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=AZN5L7H2GVZ3A&source=url"
						className="px-5 ibm-condensed text-beige font-bold text-base py-2 lg:py-0"
					>
						DONATE
					</a>
				</div>
			</div>
		</footer>
	);
};

/* Export
============================================================================= */

export default Footer;
