import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Close from "../svg/Close";
import useMenu from "../zustand/useMenu";

/* Menu
============================================================================= */

const Menu = () => {
	// Config
	const { open, setOpen } = useMenu();

	// Render
	return (
		<div
			style={{ zIndex: 100 }}
			className={`flex flex-col items-center justify-center w-full min-h-screen fixed bg-orange transition duration-300 transition-opacity top-0 ${
				open ? "opacity-100" : "opacity-0 pointer-events-none"
			}`}
		>
			<div
				className="absolute top-0 right-0 mt-1 mr-3 cursor-pointer md:left-0 md:mt-5 md:ml-8"
				onClick={() => setOpen(false)}
			>
				<svg
					width="58"
					height="57"
					viewBox="0 0 58 57"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect
						x="13.9297"
						y="14.8486"
						width="2"
						height="40"
						transform="rotate(-45 13.9297 14.8486)"
						fill="#FEE4D2"
					/>
					<rect
						x="15.3447"
						y="43.1329"
						width="2"
						height="40"
						transform="rotate(-135 15.3447 43.1329)"
						fill="#FEE4D2"
					/>
				</svg>
			</div>
			<Link
				to="/"
				onClick={() => setOpen(false)}
				className={`menu-link ${open ? "menu-link-open delay-75" : ""}`}
			>
				<span className="transition-all transition duration-300 hover:text-darkblue">
					LINE UP
				</span>
			</Link>
			<Link
				to="/artists"
				onClick={() => setOpen(false)}
				className={`menu-link ${
					open ? "menu-link-open delay-100" : ""
				}`}
			>
				<span className="transition-all transition duration-300 hover:text-darkblue">
					ARTISTS
				</span>
			</Link>
			<a
				href="https://99designs.com.au/go/homegrown-isol-aid"
				target="_blank"
				rel="noopener noreferrer"
				className={`menu-link ${
					open ? "menu-link-open delay-150" : ""
				}`}
			>
				<span className="transition-all transition duration-300 hover:text-darkblue">
					ISOL-AID HOMEGROWN
				</span>
			</a>
			<Link
				to="/howitworks"
				onClick={() => setOpen(false)}
				className={`menu-link ${
					open ? "menu-link-open delay-150" : ""
				}`}
			>
				<span className="transition-all transition duration-300 hover:text-darkblue">
					HOW IT WORKS
				</span>
			</Link>
			<Link
				to="/about"
				onClick={() => setOpen(false)}
				className={`menu-link ${
					open ? "menu-link-open delay-200" : ""
				}`}
			>
				<span className="transition-all transition duration-300 hover:text-darkblue">
					ABOUT
				</span>
			</Link>
			<Link
				to="/contact"
				onClick={() => setOpen(false)}
				className={`menu-link ${
					open ? "menu-link-open delay-300" : ""
				}`}
			>
				<span className="transition-all transition duration-300 hover:text-darkblue">
					CONTACT US
				</span>
			</Link>
			<a
				href="https://store.sound-merch.com.au/collections/isol-aid"
				target="_blank"
				rel="noopener noreferrer"
				className={`menu-link ${
					open ? "menu-link-open delay-500" : ""
				}`}
			>
				<span className="transition-all transition duration-300 hover:text-darkblue">
					MERCH
				</span>
			</a>
			<Link
				to="/faqs"
				onClick={() => setOpen(false)}
				className={`menu-link ${
					open ? "menu-link-open delay-700" : ""
				}`}
			>
				<span className="transition-all transition duration-300 hover:text-darkblue">
					FAQS
				</span>
			</Link>
		</div>
	);
};
/* Export
============================================================================= */

export default Menu;
