import create from "zustand";

/* Use  Menu
============================================================================= */

const [useMenu] = create((set) => ({
	open: false,
	setOpen: (open) => set((state) => ({ open })),
}));

/* Export
============================================================================= */

export default useMenu;
