import React from "react";

/* Newsletter Subscribe
============================================================================= */

const NewsletterSubscribe = () => {
	// Config

	// Render
	return (
		<div className="bg-darkblue w-full py-10">
			<div className="w-full container container-padding mx-auto flex flex-col items-center justify-center">
				<h2 className="text-white lg:text-3xl text-2xl font-sans pb-4 text-center ibm-plex">
					WE'LL KEEP YOU UPDATED.
				</h2>
				<p
					className="lg:w-2/5 w-full lg:px-0 px-8 text-center text-white text-base leading-loose tracking-wide"
					style={{ fontWeight: "400" }}
				>
					Sign up to our newsletter to stay in the know about new
					shows artists, merch and more.
				</p>
				<form
					className="lg:w-1/2 w-full mt-8 flex flex-row items-center justify-center mx-auto"
					name="Contact"
					method="POST"
					action="https://isolaidfestival.us19.list-manage.com/subscribe/post?u=3b3e8445afe9099bf2a103dea&amp;id=488d61bc6a"
				>
					<input
						type="text"
						className="bg-transparent border-white border-2 ibm-plex px-3 py-2 lg:w-4/5 w-3/5 placeholder-white text-white h-12 lg:text-base text-sm"
						maxLength="256"
						name="EMAIL"
						data-name="EMAIL"
						placeholder="YOUR EMAIL ADDRESS"
						id="EMAIL-2"
						required=""
					/>
					<button
						className="bg-white border-white border-2 ibm-plex px-3 py-2  text-darkblue h-12 lg:text-base text-sm"
						type="submit"
					>
						SEND
					</button>
				</form>
			</div>
		</div>
	);
};

/* Export
============================================================================= */

export default NewsletterSubscribe;
