import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./header";
import Footer from "./footer";
import Menu from "./menu";
import Donate from "./donate";
import Sponsors from "./sponsors";
import NewsletterSubscribe from "./newsletter";
import "../styles/styles.scss";
import "pure-react-carousel/dist/react-carousel.es.css";

/* Layout
============================================================================= */

export default ({ children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<>
			<img src="Card.png" style={{ width: "0px", height: "0px" }} />
			<Header siteTitle={data.site.siteMetadata.title} />
			<main className="pt-16 lg:pt-24">
				{children}
				<Donate />
				<NewsletterSubscribe />
				<Sponsors />
			</main>
			<Footer />
			<Menu />
		</>
	);
};
