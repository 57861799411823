import React from "react";

/* Donate
============================================================================= */

const Donate = () => {
	// Config

	// Render
	return (
		<>
			<div className="w-full bg-beige">
				<div className="container flex flex-col items-center justify-center w-full mx-auto container-padding">
					<p className="text-lg text-darkblue ibm-plex lg:pb-4">
						HELPING RAISE FUNDS TO
					</p>
					<h2 className="pb-4 font-sans text-3xl font-black text-center text-darkblue ibm-plex lg:text-5xl">
						SUPPORT THE ARTISTS
					</h2>
					<p className="w-full px-8 pb-8 text-base leading-loose text-center text-darkblue ibm-plex lg:w-2/5 lg:px-0">
						We love that our festival is free to watch, but we do
						ask online attendees to help support the artists if you
						can. 100% of whatever you donate will go directly to the
						Isol-Aid artists and their teams.
					</p>
					<p className="w-full px-8 pb-8 text-base leading-loose text-center text-darkblue ibm-plex lg:w-2/5 lg:px-0">
						As you know, the loss of touring and live performance
						income has caused financial strain and even strife for
						many in our music community. Throwing in even what you’d
						normally pay for a beer or two at the show will make a
						huge difference to these acts. And we’ll be eternally
						grateful, too!
					</p>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=AZN5L7H2GVZ3A&source=url"
						className="px-6 py-4 font-sans font-bold tracking-wide text-white bg-orange ibm-plex"
					>
						SUPPORT THE ARTISTS
					</a>
				</div>
			</div>
		</>
	);
};

/* Export
============================================================================= */

export default Donate;
