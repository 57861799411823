import React from "react";

/* Instagram
============================================================================= */

const Instagram = () => {
	// Config

	// Render
	return (
		<svg
			width="32"
			height="33"
			viewBox="0 0 32 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.0026 7.87297C11.4623 7.87297 7.80003 11.5352 7.80003 16.0755C7.80003 20.6159 11.4623 24.2781 16.0026 24.2781C20.5429 24.2781 24.2052 20.6159 24.2052 16.0755C24.2052 11.5352 20.5429 7.87297 16.0026 7.87297ZM16.0026 21.4083C13.0685 21.4083 10.6699 19.0167 10.6699 16.0755C10.6699 13.1343 13.0614 10.7428 16.0026 10.7428C18.9438 10.7428 21.3353 13.1343 21.3353 16.0755C21.3353 19.0167 18.9367 21.4083 16.0026 21.4083ZM26.4539 7.53744C26.4539 8.60113 25.5972 9.45066 24.5407 9.45066C23.477 9.45066 22.6275 8.59399 22.6275 7.53744C22.6275 6.48089 23.4841 5.62422 24.5407 5.62422C25.5972 5.62422 26.4539 6.48089 26.4539 7.53744ZM31.8866 9.47921C31.7652 6.91636 31.1798 4.6462 29.3023 2.77581C27.4319 0.905426 25.1618 0.320039 22.5989 0.191539C19.9575 0.0416226 12.0405 0.0416226 9.39914 0.191539C6.84342 0.3129 4.57326 0.898287 2.69573 2.76867C0.818209 4.63906 0.23996 6.90922 0.111461 9.47207C-0.0384556 12.1135 -0.0384556 20.0305 0.111461 22.6719C0.232822 25.2347 0.818209 27.5049 2.69573 29.3753C4.57326 31.2456 6.83628 31.831 9.39914 31.9595C12.0405 32.1094 19.9575 32.1094 22.5989 31.9595C25.1618 31.8382 27.4319 31.2528 29.3023 29.3753C31.1727 27.5049 31.7581 25.2347 31.8866 22.6719C32.0365 20.0305 32.0365 12.1206 31.8866 9.47921ZM28.4742 25.506C27.9174 26.9052 26.8394 27.9832 25.433 28.5471C23.3271 29.3824 18.3299 29.1896 16.0026 29.1896C13.6753 29.1896 8.67097 29.3753 6.57214 28.5471C5.17292 27.9903 4.09495 26.9123 3.53098 25.506C2.69573 23.4 2.88848 18.4028 2.88848 16.0755C2.88848 13.7483 2.70287 8.74391 3.53098 6.64508C4.08781 5.24586 5.16578 4.16789 6.57214 3.60392C8.67811 2.76867 13.6753 2.96142 16.0026 2.96142C18.3299 2.96142 23.3342 2.77581 25.433 3.60392C26.8323 4.16075 27.9102 5.23872 28.4742 6.64508C29.3095 8.75105 29.1167 13.7483 29.1167 16.0755C29.1167 18.4028 29.3095 23.4072 28.4742 25.506Z"
				fill="#FEE4D2"
			/>
		</svg>
	);
};

/* Export
============================================================================= */

export default Instagram;
