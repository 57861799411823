import React from "react";
import Image from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

/* Sponsors
============================================================================= */

const Sponsors = () => {
	// Config
	const data = useStaticQuery(graphql`
		query {
			sanityIsolaid {
				sponsors {
					logo {
						asset {
							fluid {
								base64
								aspectRatio
								src
								srcSet
								srcWebp
								srcSetWebp
								sizes
							}
						}
					}
					name
					link
				}
			}
		}
	`);

	// Render Sponsors
	const renderSponsors = () => {
		return data.sanityIsolaid.sponsors.map((el, i) => {
			return (
				<div className="w-full flex flex-row items-center justify-center mb-4 lg:mb-0">
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={el.link}
						className="h-24 w-48"
					>
						<Image
							fluid={el.logo.asset.fluid}
							imgStyle={{ width: "100%" }}
							className="w-full h-full"
							imgStyle={{ objectFit: "contain" }}
						/>
					</a>
				</div>
			);
		});
	};

	// Render
	return (
		<div className="w-full py-8">
			<div className="w-full container container-padding mx-auto flex flex-col items-center justify-center">
				<h2 className="text-darkblue lg:text-3xl text-2xl font-sans font-black pb-6 text-center ibm-plex">
					OUR BELOVED SPONSORS
				</h2>
				<div className="lg:w-3/4 w-full grid lg:grid-cols-3 grid-cols-1 gap-4 lg:gap-0 pt-4">
					{renderSponsors()}
				</div>
			</div>
		</div>
	);
};

/* Export
============================================================================= */

export default Sponsors;
