import { Link } from "gatsby";
import Logo from "../svg/Logo";
import IsolateLogo from "../svg/IsolateLogo";
import React from "react";
import useMenu from "../zustand/useMenu";

/* Header
============================================================================= */

const Header = ({ siteTitle }) => {
	// Config
	const { open, setOpen } = useMenu();

	// Render
	return (
		<header
			className="fixed flex flex-row-reverse items-center justify-between w-screen h-16 px-4 py-4 lg:flex-row md:px-8 lg:py-10 lg:h-24"
			style={{ backgroundColor: "#FEE4D2", zIndex: 90 }}
		>
			<div className="flex justify-end lg:justify-start w-120">
				<button
					onClick={() => setOpen(true)}
					style={{ borderWidth: "3px" }}
					className="w-12 h-12 text-xs font-bold transition-all transition duration-200 rounded-full lg:h-16 lg:w-16 border-orange text-orange hover:border-darkblue hover:text-darkblue ibm-plex lg:text-sm"
				>
					MENU
				</button>
			</div>

			<div className="flex items-center justify-start flex-1 lg:justify-center">
				<Link to="/" className="w-32 lg:w-24">
					<Logo />
				</Link>
			</div>

			<div className="justify-end hidden w-120 lg:flex">
				<a
					href="https://store.sound-merch.com.au/collections/isol-aid"
					target="_blank"
					rel="noopener noreferrer"
					className="hidden px-6 py-4 font-sans font-bold tracking-wide text-orange ibm-plex lg:block"
				>
					GET MERCH
				</a>
				{/* <a
					href="https://www.ausmusictshirtday.org.au/teams/isol-aid-festival-2"
					target="_blank"
					rel="noopener noreferrer"
					className="px-6 py-4 font-sans font-bold tracking-wide text-white bg-orange ibm-plex"
				>
					DONATE TO SUPPORT ACT
				</a> */}
				<a
					href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=AZN5L7H2GVZ3A&source=url"
					target="_blank"
					rel="noopener noreferrer"
					className="px-6 py-4 font-sans font-bold tracking-wide text-white bg-orange ibm-plex"
				>
					SUPPORT THE ARTISTS
				</a>
			</div>
		</header>
	);
};
/* Export
============================================================================= */

export default Header;
